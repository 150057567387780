export const PROVIDERS = {
  netflix: 8,
  appleTV: 2,
  disney: 337,
  amazon: 10,
  hboMAX: 384,
};

export const LANGUAGES = {
  pl: { lang: "pl-PL", region: "PL" },
  us: { lang: "en-US", region: "US" },
  de: { lang: "de-DE", region: "DE" },
  fr: { lang: "fr-FR", region: "FR" },
  es: { lang: "es-ES", region: "ES" },
};

export const CATEGORIES = [
  { value: "28", label: "Action" },
  { value: "12", label: "Adventure" },
  { value: "16", label: "Animation" },
  { value: "35", label: "Comedy" },
  { value: "80", label: "Crime" },
  { value: "99", label: "Documentary" },
  { value: "18", label: "Drama" },
  { value: "10751", label: "Family" },
  { value: "14", label: "Fantasy" },
  { value: "36", label: "History" },
  { value: "27", label: "Horror" },
  { value: "10402", label: "Music" },
  { value: "9648", label: "Mystery" },
  { value: "10749", label: "Romance" },
  { value: "878", label: "Sci&fi" },
  { value: "10770", label: "TV Movie" },
  { value: "53", label: "Thriller" },
  { value: "10752", label: "War" },
  { value: "37", label: "Western" },
];

export const SORTING = [
  { value: "sort1", label: "Avg. Rate" },
  { value: "sort2", label: "Votes" },
  { value: "sort3", label: "Newest" },
  { value: "sort4", label: "Earn Money" },
];
