export const TERMS_OF_USE = [
  {
    title: '1. Rule number one',
    rules: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius elementum mauris, faucibus malesuada mauris porta non. Ut massa sem, tempor quis dapibus a, aliquet non ex. Nulla dictum consequat sollicitudin. Fusce imperdiet sapien nunc, nec consequat nisi posuere quis. Duis condimentum magna nec nibh porta tristique. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec porta metus diam. Donec nec nulla pharetra, dignissim risus a, vehicula nibh. Vestibulum volutpat elit sit amet mauris consequat eleifend. Sed cursus vel eros ut malesuada. Ut gravida ante nec tortor maximus tincidunt. Morbi maximus accumsan rhoncus. Morbi tincidunt eget leo eu vestibulum. Nulla pretium arcu sit amet sem posuere lobortis.',
      'Suspendisse potenti. Sed vestibulum est eget leo pulvinar, a ultricies felis mollis. Nullam a quam aliquet ante accumsan tincidunt commodo id odio. Nullam at tellus aliquet lacus vulputate lacinia vitae viverra sapien. Vestibulum placerat orci sit amet mi lobortis, ut placerat enim luctus. Cras non imperdiet sapien, non sagittis magna. Duis tincidunt ligula consequat sodales ultrices. In blandit est quis augue aliquam fermentum. Etiam lorem eros, tincidunt ac cursus vitae, mollis sit amet nunc. Mauris eu bibendum augue. In varius vitae metus quis gravida. Suspendisse potenti. Integer dictum vestibulum elit, vel ornare risus elementum nec. Mauris eu est viverra, placerat mauris eget, mollis odio. Cras nec erat sem. Duis vel arcu ac nulla aliquam tempor.',
      'Integer augue lorem, porttitor sed eros nec, gravida tempus leo. Vivamus ultricies gravida nisi, aliquet facilisis dui dignissim vel. Proin vulputate justo ac neque porta ullamcorper. Aliquam ac tempus lectus, ac venenatis lacus. Phasellus imperdiet eros diam. Donec nec dapibus ex. Pellentesque et varius velit, at laoreet erat. Vivamus sem quam, fringilla at metus sed, placerat iaculis risus. Mauris tellus nisl, vestibulum nec euismod sed, euismod eu ipsum. Donec tempor bibendum nisl et scelerisque. Cras mattis turpis ex, ut mollis nisl ullamcorper eu. Nam dui quam, tincidunt vel dui vel, varius lacinia lectus. Morbi commodo condimentum lorem vel interdum. Mauris fermentum orci vel nibh vulputate bibendum. Proin eget faucibus nibh. In condimentum nisi.',
    ],
  },
  {
    title: '2. Rule number two',
    rules: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius elementum mauris, faucibus malesuada mauris porta non. Ut massa sem, tempor quis dapibus a, aliquet non ex. Nulla dictum consequat sollicitudin. Fusce imperdiet sapien nunc, nec consequat nisi posuere quis. Duis condimentum magna nec nibh porta tristique. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec porta metus diam. Donec nec nulla pharetra, dignissim risus a, vehicula nibh. Vestibulum volutpat elit sit amet mauris consequat eleifend. Sed cursus vel eros ut malesuada. Ut gravida ante nec tortor maximus tincidunt. Morbi maximus accumsan rhoncus. Morbi tincidunt eget leo eu vestibulum. Nulla pretium arcu sit amet sem posuere lobortis.',
      'Suspendisse potenti. Sed vestibulum est eget leo pulvinar, a ultricies felis mollis. Nullam a quam aliquet ante accumsan tincidunt commodo id odio. Nullam at tellus aliquet lacus vulputate lacinia vitae viverra sapien. Vestibulum placerat orci sit amet mi lobortis, ut placerat enim luctus. Cras non imperdiet sapien, non sagittis magna. Duis tincidunt ligula consequat sodales ultrices. In blandit est quis augue aliquam fermentum. Etiam lorem eros, tincidunt ac cursus vitae, mollis sit amet nunc. Mauris eu bibendum augue. In varius vitae metus quis gravida. Suspendisse potenti. Integer dictum vestibulum elit, vel ornare risus elementum nec. Mauris eu est viverra, placerat mauris eget, mollis odio. Cras nec erat sem. Duis vel arcu ac nulla aliquam tempor.',
      'Integer augue lorem, porttitor sed eros nec, gravida tempus leo. Vivamus ultricies gravida nisi, aliquet facilisis dui dignissim vel. Proin vulputate justo ac neque porta ullamcorper. Aliquam ac tempus lectus, ac venenatis lacus. Phasellus imperdiet eros diam. Donec nec dapibus ex. Pellentesque et varius velit, at laoreet erat. Vivamus sem quam, fringilla at metus sed, placerat iaculis risus. Mauris tellus nisl, vestibulum nec euismod sed, euismod eu ipsum. Donec tempor bibendum nisl et scelerisque. Cras mattis turpis ex, ut mollis nisl ullamcorper eu. Nam dui quam, tincidunt vel dui vel, varius lacinia lectus. Morbi commodo condimentum lorem vel interdum. Mauris fermentum orci vel nibh vulputate bibendum. Proin eget faucibus nibh. In condimentum nisi.',
    ],
  },
  {
    title: '3. Rule number three',
    rules: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius elementum mauris, faucibus malesuada mauris porta non. Ut massa sem, tempor quis dapibus a, aliquet non ex. Nulla dictum consequat sollicitudin. Fusce imperdiet sapien nunc, nec consequat nisi posuere quis. Duis condimentum magna nec nibh porta tristique. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec porta metus diam. Donec nec nulla pharetra, dignissim risus a, vehicula nibh. Vestibulum volutpat elit sit amet mauris consequat eleifend. Sed cursus vel eros ut malesuada. Ut gravida ante nec tortor maximus tincidunt. Morbi maximus accumsan rhoncus. Morbi tincidunt eget leo eu vestibulum. Nulla pretium arcu sit amet sem posuere lobortis.',
      'Suspendisse potenti. Sed vestibulum est eget leo pulvinar, a ultricies felis mollis. Nullam a quam aliquet ante accumsan tincidunt commodo id odio. Nullam at tellus aliquet lacus vulputate lacinia vitae viverra sapien. Vestibulum placerat orci sit amet mi lobortis, ut placerat enim luctus. Cras non imperdiet sapien, non sagittis magna. Duis tincidunt ligula consequat sodales ultrices. In blandit est quis augue aliquam fermentum. Etiam lorem eros, tincidunt ac cursus vitae, mollis sit amet nunc. Mauris eu bibendum augue. In varius vitae metus quis gravida. Suspendisse potenti. Integer dictum vestibulum elit, vel ornare risus elementum nec. Mauris eu est viverra, placerat mauris eget, mollis odio. Cras nec erat sem. Duis vel arcu ac nulla aliquam tempor.',
      'Integer augue lorem, porttitor sed eros nec, gravida tempus leo. Vivamus ultricies gravida nisi, aliquet facilisis dui dignissim vel. Proin vulputate justo ac neque porta ullamcorper. Aliquam ac tempus lectus, ac venenatis lacus. Phasellus imperdiet eros diam. Donec nec dapibus ex. Pellentesque et varius velit, at laoreet erat. Vivamus sem quam, fringilla at metus sed, placerat iaculis risus. Mauris tellus nisl, vestibulum nec euismod sed, euismod eu ipsum. Donec tempor bibendum nisl et scelerisque. Cras mattis turpis ex, ut mollis nisl ullamcorper eu. Nam dui quam, tincidunt vel dui vel, varius lacinia lectus. Morbi commodo condimentum lorem vel interdum. Mauris fermentum orci vel nibh vulputate bibendum. Proin eget faucibus nibh. In condimentum nisi.',
    ],
  },
  {
    title: '4. Rule number four',
    rules: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius elementum mauris, faucibus malesuada mauris porta non. Ut massa sem, tempor quis dapibus a, aliquet non ex. Nulla dictum consequat sollicitudin. Fusce imperdiet sapien nunc, nec consequat nisi posuere quis. Duis condimentum magna nec nibh porta tristique. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec porta metus diam. Donec nec nulla pharetra, dignissim risus a, vehicula nibh. Vestibulum volutpat elit sit amet mauris consequat eleifend. Sed cursus vel eros ut malesuada. Ut gravida ante nec tortor maximus tincidunt. Morbi maximus accumsan rhoncus. Morbi tincidunt eget leo eu vestibulum. Nulla pretium arcu sit amet sem posuere lobortis.',
      'Suspendisse potenti. Sed vestibulum est eget leo pulvinar, a ultricies felis mollis. Nullam a quam aliquet ante accumsan tincidunt commodo id odio. Nullam at tellus aliquet lacus vulputate lacinia vitae viverra sapien. Vestibulum placerat orci sit amet mi lobortis, ut placerat enim luctus. Cras non imperdiet sapien, non sagittis magna. Duis tincidunt ligula consequat sodales ultrices. In blandit est quis augue aliquam fermentum. Etiam lorem eros, tincidunt ac cursus vitae, mollis sit amet nunc. Mauris eu bibendum augue. In varius vitae metus quis gravida. Suspendisse potenti. Integer dictum vestibulum elit, vel ornare risus elementum nec. Mauris eu est viverra, placerat mauris eget, mollis odio. Cras nec erat sem. Duis vel arcu ac nulla aliquam tempor.',
      'Integer augue lorem, porttitor sed eros nec, gravida tempus leo. Vivamus ultricies gravida nisi, aliquet facilisis dui dignissim vel. Proin vulputate justo ac neque porta ullamcorper. Aliquam ac tempus lectus, ac venenatis lacus. Phasellus imperdiet eros diam. Donec nec dapibus ex. Pellentesque et varius velit, at laoreet erat. Vivamus sem quam, fringilla at metus sed, placerat iaculis risus. Mauris tellus nisl, vestibulum nec euismod sed, euismod eu ipsum. Donec tempor bibendum nisl et scelerisque. Cras mattis turpis ex, ut mollis nisl ullamcorper eu. Nam dui quam, tincidunt vel dui vel, varius lacinia lectus. Morbi commodo condimentum lorem vel interdum. Mauris fermentum orci vel nibh vulputate bibendum. Proin eget faucibus nibh. In condimentum nisi.',
    ],
  },
  {
    title: '5. Rule number five',
    rules: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius elementum mauris, faucibus malesuada mauris porta non. Ut massa sem, tempor quis dapibus a, aliquet non ex. Nulla dictum consequat sollicitudin. Fusce imperdiet sapien nunc, nec consequat nisi posuere quis. Duis condimentum magna nec nibh porta tristique. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec porta metus diam. Donec nec nulla pharetra, dignissim risus a, vehicula nibh. Vestibulum volutpat elit sit amet mauris consequat eleifend. Sed cursus vel eros ut malesuada. Ut gravida ante nec tortor maximus tincidunt. Morbi maximus accumsan rhoncus. Morbi tincidunt eget leo eu vestibulum. Nulla pretium arcu sit amet sem posuere lobortis.',
      'Suspendisse potenti. Sed vestibulum est eget leo pulvinar, a ultricies felis mollis. Nullam a quam aliquet ante accumsan tincidunt commodo id odio. Nullam at tellus aliquet lacus vulputate lacinia vitae viverra sapien. Vestibulum placerat orci sit amet mi lobortis, ut placerat enim luctus. Cras non imperdiet sapien, non sagittis magna. Duis tincidunt ligula consequat sodales ultrices. In blandit est quis augue aliquam fermentum. Etiam lorem eros, tincidunt ac cursus vitae, mollis sit amet nunc. Mauris eu bibendum augue. In varius vitae metus quis gravida. Suspendisse potenti. Integer dictum vestibulum elit, vel ornare risus elementum nec. Mauris eu est viverra, placerat mauris eget, mollis odio. Cras nec erat sem. Duis vel arcu ac nulla aliquam tempor.',
      'Integer augue lorem, porttitor sed eros nec, gravida tempus leo. Vivamus ultricies gravida nisi, aliquet facilisis dui dignissim vel. Proin vulputate justo ac neque porta ullamcorper. Aliquam ac tempus lectus, ac venenatis lacus. Phasellus imperdiet eros diam. Donec nec dapibus ex. Pellentesque et varius velit, at laoreet erat. Vivamus sem quam, fringilla at metus sed, placerat iaculis risus. Mauris tellus nisl, vestibulum nec euismod sed, euismod eu ipsum. Donec tempor bibendum nisl et scelerisque. Cras mattis turpis ex, ut mollis nisl ullamcorper eu. Nam dui quam, tincidunt vel dui vel, varius lacinia lectus. Morbi commodo condimentum lorem vel interdum. Mauris fermentum orci vel nibh vulputate bibendum. Proin eget faucibus nibh. In condimentum nisi.',
    ],
  },
];
